export default defineNuxtRouteMiddleware((to, from) => {
  console.log("routeMiddleware", `IsClient: ${to} ${from}`);

  // return new Promise<void>((resolve, reject) => {
  //   console.log("routerMiddleware Promise");
  //   setTimeout(() => {
  //     console.log("routerMiddleware Promise complete");
  //     if (isClient) {
  //       reject(new Error("I AM REJECTING"));
  //     } else {
  //       resolve();
  //     }
  //   }, 5000);
  // });
});
